'use strict';

var Galleria = (typeof window !== "undefined" ? window.Galleria : typeof global !== "undefined" ? global.Galleria : null);

var theme = require('./theme.js');

var dom;
var options;

var getDefaults = function() {

	return {
		// Target data items.
		target: '[data-fullscreen-slideshow]',
		data: []
	};

};

var launch = function(target, musicIsPaused) {

	// Open galleria slideshow.

	Galleria.run('.fullscreen-slideshow__target', {
		dataSource: options.data,
		show: target,
		autoplay: options.isAutoPlay,
		// Pass in custom options.
		_musicIsPaused: musicIsPaused,
		_hasMusic: options.hasMusic,
		_showControls: options.showControls
	});

};

var addEventHandlers = function() {

	Galleria.ready(function() {

		var galleriaInstance = this;

		galleriaInstance.enterFullscreen();

	});

};

var setupDom = function() {

	dom = {};

	dom.fullscreenSlideshow = $('.fullscreen-slideshow');
	dom.imgs = $(options.target);

};

var saveData = function() {

	dom.imgs.each(function(i) {

		options.data.push(dom.imgs.eq(i).data('fullscreen-slideshow'));

	});

};

var destroy = function() {

	dom = null;
	options = null;

};

var init = function(opts) {

	// https://code.google.com/p/chromium/issues/detail?id=467934
	// Chrome on Yosemite is telling Galleria that this is a touch device.
	// Hardcode this value to false to fix. This makes this a desktop only
	// module in essence. If this module needs to work on mobile, other
	// alternatives will need to be researched.
	Galleria.TOUCH = false;

	options = getDefaults();
	// Copy over a custom target if it exists.
	$.extend(options, opts);
	setupDom();
	// Copy over module config.
	$.extend(options, dom.fullscreenSlideshow.data('fullscreen-slideshow-options'));
	saveData();
	theme.add();
	addEventHandlers();

};

module.exports = {

	init: init,
	destroy: destroy,
	launch: launch

};
