'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var scroller = require('portfolio-scroller');
var formWidget = require('form-widget');

var showThankYou = function() {

	var $jspContainer = $('.jspContainer');
	var $popup = $('<div class="form-popup vert-horiz-center">' +
		formWidget.submissionHeaderText +
		'<br><br>' +
		formWidget.submissionMsgText +
		'</div>');

	$jspContainer.prepend($popup);

	window.setTimeout(function() {

		$popup.remove();
		formWidget.reset();

	}, 3000);

};

var destroy = function() {

	console.log('call formWidget.destroy()');
	formWidget.destroy();

};

module.exports = {

	init: function() {

		formWidget.init({
			elId: 'contact'
		});

		formWidget.form.on('fbWidget-form-submit-success', showThankYou);
		formWidget.form.on('fbWidget-familyfriends-field-added fbWidget-familyfriends-field-removed', scroller.refresh);

		console.log('adding destroy method for forms...');
		window.core.addUnload(destroy);

	}

};
