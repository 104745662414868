'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var dom = require('form-widget-dom');
var throttle = require('throttle');
var emitter = require('emitter');

var forceOneColMode = false;
var breakPoints = {
	// Once the form is at least this wide, move to two col mode.
	twoColMode: 450,
	// Once the form is at least this wide, unstack the header.
	noHeaderStack: 800
};

// Get the exact decimal width. Useful for when page is zoomed to avoid
// rounding issues.
var getFormWidth = function() {

	return parseFloat(window.getComputedStyle(dom.inputContainer[0]).width);

};

var adjustHeaderLayout = function(formWidth) {

	if (formWidth >= breakPoints.noHeaderStack) {

		dom.headerContainer.addClass('fb-header-multi-col-mode');

	} else {

		dom.headerContainer.removeClass('fb-header-multi-col-mode');

	}

};

var adjustFormLayout = function(formWidth) {

	if (formWidth >= breakPoints.twoColMode && !forceOneColMode) {

		dom.fbForm.removeClass('fb-one-col-mode');
		dom.fbForm.addClass('fb-two-col-mode');

	} else {

		// Set mobile first view.

		dom.fbForm.removeClass('fb-two-col-mode');
		dom.fbForm.addClass('fb-one-col-mode');

	}

};

var adjust = function() {

	var formWidth = getFormWidth();

	emitter.emit('fbWidget-window-resize', {
		formWidth: formWidth
	});

	adjustHeaderLayout(formWidth);
	// adjustRatingLayout(formWidth);
	adjustFormLayout(formWidth);

	emitter.emit('fbWidget-layout-adjusted');

};

var addEventHandlers = function() {

	dom.window.off('.fb-layout');

	dom.window.on('resize.fb-layout', throttle(adjust, 20));

	emitter.on('fbWidget-destroy', function() {

		$(window).off('.fb-layout');

	});

};

module.exports = {

	init: function(forceOneCol) {

		forceOneColMode = forceOneCol;
		addEventHandlers();
		adjust();

	}

};
