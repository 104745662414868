
; require("/srv/www/html/client/modules/vendor/date-format/dateFormat.js");
; var __browserify_shim_require__=require;(function browserifyShim(module, exports, require, define, browserify_shim__define__module__export__) {
window.calendar = {

	dom: {},
	data: {},

	init: function(bookedDates, lightColor, darkColor, textColor, selectedTextColor, activateBookingForm) {

		if(typeof core !== "undefined"){
			core.addUnload(calendar.destroy);
		}

		var date = new Date();

		calendar.data.format = 'mm/dd/yy';
		calendar.data.selectedYear = date.getFullYear();
		calendar.data.selectedMonth = date.getMonth() + 1;
		calendar.data.selectedDay = date.getDate();
		calendar.data.lightColor = lightColor; //
		calendar.data.darkColor = darkColor;
		calendar.data.textColor = textColor;
		calendar.data.selectedTextColor = selectedTextColor;
		calendar.data.activateBookingForm = activateBookingForm;

		calendar.dom.formWrapper = $('#bookingForm');
		calendar.dom.picker = $('#datePicker');
		calendar.dom.form = $('#contact');
		calendar.dom.alerter = $('#errorAlert');

		calendar.dom.close = $('#closeForm').click(function() {

			calendar.dom.formWrapper.hide();
			calendar.dom.form[0].reset();

			calendar.dayHover();

			return false;

		});

		calendar.dom.picker.datepicker({
			minDate: [(date.getMonth() + 1), date.getDate(), date.getFullYear()].join('/'),
			dayNamesMin: ['SUN','MON','TUE','WED','THU','FRI','SAT'],
			onSelect: function(date, inst) {

				calendar.data.selectedDay = date.split('/')[1];

				var dateToFormat = new Date(calendar.data.selectedYear, calendar.data.selectedMonth - 1, calendar.data.selectedDay);

				$('#bookDate').val(date);
				$('#selectedDate').html(dateToFormat.format("mmmm d, yyyy"));

				console.log("activateBookingForm: " + calendar.data.activateBookingForm);
				if(calendar.data.activateBookingForm){
					calendar.dom.formWrapper.show();
				}

			},
			beforeShowDay: function(date) {

				if ($.inArray($.datepicker.formatDate(calendar.data.format, date), bookedDates) >= 0) {
					return [false, 'booked-date'];
				} else {
					return [true];
				}

			}
		});

		calendar.data.fields = calendar.dom.form.children('input[type="text"], textarea');

		calendar.data.fields.each(function() {

			$(this).data('placeholder', $(this).val());

		});

		calendar.data.fields.focus(function() {

			$(this).val('');

		}).blur(function() {

			if ($.trim($(this).val()) === '') {
				$(this).val($(this).data('placeholder'));
			}

		});

		calendar.dom.form.submit(function() {

        	var errors = [];

        	calendar.data.fields.each(function() {

				var val = $.trim($(this).val()).toUpperCase();
        		var placeholder = $(this).data('placeholder');
        		var required = $(this).attr('data-required');

				if (required == 'true' && (val === '' ||  val === placeholder.toUpperCase())) {
					errors.push(placeholder + ' is required');
				}

			});

			if (errors.length) {

            	calendar.dom.alerter.css({
					left: 12,
					top: 28
				}).html(errors.join('<br>')).show();

				window.setTimeout(function() {

					calendar.dom.alerter.hide();

				}, 3000);

            } else {

            	calendar.data.fields.each(function() {

					if ($.trim($(this).val()).toUpperCase() === $(this).data('placeholder').toUpperCase()) {
						$(this).val('');
					}

				});

	            // send

	            $.ajax({
	            	type: 'post',
	            	data: calendar.dom.form.serialize(),
	            	url: '?load=calendar',
	            	cache: false,
	            	complete: function() {

	            		calendar.dom.alerter.html('Your message was sent').css({
							left: 12,
							top: 28
						}).show();

	            		window.setTimeout(function() {
	            			calendar.dom.alerter.hide();
	            		}, 3000);

            			calendar.dom.form[0].reset();

	            	}
	            });

			}

            return false;

        });


		$('#bookedBlock').fadeIn();
		$('#bookedDates').fadeIn();
		$('#calendarYears').fadeIn();
		$('#calendarMonths').fadeIn();


		$('#datePickerWrapper').css('width', calendar.dom.picker.width()/* - 20*/);

		calendar.dayHover();

		// create clickable years

		$('#calendarYears a').each(function() {

			if ($(this).attr('data-year') == calendar.data.selectedYear) {
				$(this).addClass('selected');
			}

			$(this).click(function() {

				if (calendar.isPastDate(new Date($(this).attr('data-year'), calendar.data.selectedMonth - 1, 1))) {
					return false;
				}

				calendar.data.selectedYear = $(this).attr('data-year');

				calendar.setDate(calendar.data.selectedMonth, calendar.data.selectedDay, calendar.data.selectedYear);

				$('#calendarYears a').removeClass('selected').css('color', calendar.data.textColor);
				$(this).addClass('selected').css('color', calendar.data.selectedTextColor);

				calendar.dayHover();

				return false;

			}).hover(function() {

				if (!$(this).hasClass('selected')) {

					$(this).animate({
						color: calendar.data.selectedTextColor
					}, { duration: .25 * 1000, queue: false });

				}

			}, function() {

				if (!$(this).hasClass('selected')) {

					$(this).animate({
						color: calendar.data.textColor
					}, { duration: .25 * 1000, queue: false });

				}

			});

		});

		// create clickable months

		$('#calendarMonths a').each(function() {

			if ($(this).attr('data-month') == calendar.data.selectedMonth) {
				$(this).addClass('selected');
			}

			$(this).click(function() {

				if (calendar.isPastDate(new Date(calendar.data.selectedYear, $(this).attr('data-month') - 1, 1))) {
					return false;
				}

				calendar.data.selectedMonth = $(this).attr('data-month');

				calendar.setDate(calendar.data.selectedMonth, calendar.data.selectedDay, calendar.data.selectedYear);

				$('#calendarMonths a').removeClass('selected').css('color', calendar.data.textColor);
				$(this).addClass('selected').css('color', calendar.data.selectedTextColor);

				calendar.dayHover();

				return false;

			}).hover(function() {

				if (!$(this).hasClass('selected')) {

					$(this).animate({
						color: calendar.data.selectedTextColor
					}, { duration: .25 * 1000, queue: false });

				}

			}, function() {

				if (!$(this).hasClass('selected')) {

					$(this).animate({
						color: calendar.data.textColor
					}, { duration: .25 * 1000, queue: false });

				}

			});

		});

		calendar.resize();

		$(window).bind('resize.bookForm', calendar.resize);

	},

	dayHover: function() {

		$('a.ui-state-default').hover(function() {

			$(this).stop(false, true).animate({
				'color': calendar.data.selectedTextColor,
				'background-color': calendar.data.lightColor
			}, { duration: .25 * 1000, queue: false });

		}, function() {

			$(this).stop(false, true).animate({
				'color': calendar.data.lightColor,
				'background-color': calendar.data.darkColor
			}, { duration: .25 * 1000, queue: false });

		});

	},

	setDate: function(m, d, y) {

		m = parseInt(m);
		d = parseInt(d);
		y = parseInt(y);

		var dim = calendar.daysInMonth(m, y);

		if (dim < d) {
			d = dim;
		}

		calendar.dom.picker.datepicker('setDate', $.datepicker.formatDate(calendar.data.format, new Date(y, m - 1, d)));

	},

	isPastDate: function(date) {

		var d = new Date();

		return new Date(d.getFullYear(), d.getMonth(), 1) > date;

	},

	daysInMonth: function(month, year) {

		return new Date(year, month, 0).getDate();

	},

	resize: function() {

		if(typeof core !== "undefined"){

			calendar.dom.formWrapper.css({
				top: (core.dom.content.height() / 2) - calendar.dom.formWrapper.outerHeight() / 2,
				left: (core.dom.content.width() / 2) - calendar.dom.formWrapper.outerWidth() / 2
			});

		}

	},

	destroy: function() {

		$(window).unbind('resize.bookForm');

		calendar.dom.form.unbind();

		$('#calendarYears a').unbind();
		$('#calendarMonths a').unbind();
		$('a.ui-state-default').unbind();

		calendar.dom.picker.datepicker('destroy');
		calendar.dom.picker.remove();

		for (var d in calendar.dom) {
			delete calendar.dom[d];
		}

		calendar.dom = {};
		calendar.data = {};

	}

};

; browserify_shim__define__module__export__(typeof calendar != "undefined" ? calendar : window.calendar);

}).call(global, undefined, undefined, undefined, undefined, function defineExport(ex) { module.exports = ex; });
