'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var domCache = require('dom-cache');
var throttle = require('throttle');
var loadImage = require('load-image');

var dom = {};
var state = {
	customScrollerYOffset: 0
};

var options = {
	target: '[data-lazy-image]',
	customScroller: '',
	customScrollerEvent: '',
	addLoaders: false
};

var isCustomScroller = function() {

	return !!options.customScroller;

};

var addLoaders = function() {

	if (options.addLoaders) {

		dom.lazyImgs.attr('src', '/client/modules/lazy-image/img/loader.gif');

	}

};

var loadImages = function() {

	var containerBottom;

	refresh();

	if (!dom.lazyImgs.length) {

		destroy();
		return;

	}

	if (isCustomScroller()) {

		containerBottom = dom.customScroller.height() + state.customScrollerYOffset;

	} else {

		containerBottom = domCache.window.height() + domCache.window[0].pageYOffset;

	}

	dom.lazyImgs.each(function(i) {

		var $img = dom.lazyImgs.eq(i);
		var imgSrc = $img.attr('data-lazy-image');
		var isBackground = $img.is('img') ? false : true;
		var topOfImg;

		if (isCustomScroller()) {

			topOfImg = $img.offset().top - dom.customScroller.offset().top + state.customScrollerYOffset;

		} else {

			topOfImg = $img.offset().top;

		}

		if (topOfImg < containerBottom) {

			$img.removeAttr('data-lazy-image');

			loadImage(imgSrc, function() {

				if (isBackground) {

					$img.css('background-image', 'url(' + imgSrc + ')');

				} else {

					$img.attr('src', imgSrc);

				}

				$img.addClass('lazy-image--loaded');

				domCache.document.triggerHandler({
					type: 'lazy_image_loaded',
					img: $img
				});

			});

		}

	});

};

var setupDom = function() {

	dom.lazyImgs = $(options.target);
	dom.customScroller = $(options.customScroller);

};

var refresh = function() {

	dom.lazyImgs = $(options.target);

};

var addEventHandlers = function() {

	if (isCustomScroller()) {

		dom.customScroller.off('.lazy-image');

		dom.customScroller.on(options.customScrollerEvent + '.lazy-image', throttle(function(e) {

			state.customScrollerYOffset = e.yOffset;

			loadImages();

		}));

	} else {

		domCache.window.off('.lazy-image');

		domCache.window.on('scroll.lazy-image resize.lazy-image', throttle(loadImages));

	}

};

var destroy = function() {

	dom = {};
	domCache.window.off('.lazy-image');

	domCache.document.triggerHandler({
		type: 'lazy_image_destroyed'
	});

};

var init = function(opts) {

	$.extend(options, opts);

	setupDom();

	if (dom.lazyImgs.length) {

		addLoaders();

		addEventHandlers();

		loadImages();

	}

};

module.exports = {

	init: init,
	destroy: destroy

};
