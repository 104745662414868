'use strict';

var dom = require('form-widget-dom');
var Modernizr = (typeof window !== "undefined" ? window.Modernizr : typeof global !== "undefined" ? global.Modernizr : null);

var useNativeDate;
var useNativeTime;
var useCustomSelect;
var useJqueryUIDatepicker;
var path = '/forms/widget/';
var guidQueryName = 'form_guid';
var guid;

var addHooks = function() {

	var classes = '';

	classes += useNativeDate ? ' fb-use-native-date' : '';
	classes += useNativeTime ? ' fb-use-native-time' : '';
	classes += useCustomSelect ? ' fb-use-custom-select' : '';
	classes += useJqueryUIDatepicker ? ' fb-use-jquery-ui-datepicker' : '';

	dom.fbForm.addClass(classes);

};

module.exports = {

	init: function() {

		useNativeDate = Modernizr.touch && Modernizr.inputtypes.date;
		useNativeTime = Modernizr.touch && Modernizr.inputtypes.time;

		// Use custom JS select boxes if we don't have native time support
		// on a touch device. This is necessary to get a consistent and
		// properly layed out UI.
		useCustomSelect = !useNativeTime;

		// Use custom JS date picker if we don't have native date support
		// on a touch device.
		useJqueryUIDatepicker = !useNativeDate;

		guid = dom.fbForm.attr('data-form-guid');

		addHooks();

	},

	/*get useNativeDate() {

		return useNativeDate;

	},*/

	get useNativeTime() {

		return useNativeTime;

	},

	get useCustomSelect() {

		return useCustomSelect;

	},

	get useJqueryUIDatepicker() {

		return useJqueryUIDatepicker;

	},

	get path() {

		return path;

	},

	get statesUrl() {

		return path + 'get_states.cfm?' + guidQueryName + '=' + guid;

	},

	get submitUrl() {

		return path + 'submit_action.cfm?' + guidQueryName + '=' + guid;

	}

};