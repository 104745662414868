'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var dom = {};

var setupDom = function() {

	// Setup cache.
	dom.window = $(window);
	dom.document = $(document);
	dom.html = $('html');
	dom.head = $('head');
	dom.body = $('body');

};

var triggerCustomEvent = function(e) {

	dom.document.triggerHandler(e);

};

var init = function() {

	setupDom();

};

module.exports = {

	// Add accessor properties for the cache.

	get window() {

		return dom.window;

	},

	get document() {

		return dom.document;

	},

	get html() {

		return dom.html;

	},

	get head() {

		return dom.head;

	},

	get body() {

		return dom.body;

	},

	triggerCustomEvent: triggerCustomEvent

};

init();
