// Required global dependencies:
//	1. jQuery UI
//		- v1.11.2
//		- core.css, datepicker.css
//		- core.js, datepicker.js
//	2. Modernizr
//		- v2.8.3
//		- http://modernizr.com/download/#-inputtypes-touch-cssclasses-teststyles-prefixes
//
//	This module comes bundled with the notify module (js and css).

'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var emitter = require('emitter');
var placeholderToggle = require('placeholder-toggle');
var scrollElement = require('scroll-element');
var dom = require('form-widget-dom');
var settings = require('form-widget-settings');
var datepicker = require('form-widget-datepicker');
var selectmenu = require('form-widget-selectmenu');
var time = require('form-widget-time');
var rating = require('form-widget-rating');
var fileupload = require('form-widget-fileupload');
var familyfriends = require('form-widget-familyfriends');
var address = require('form-widget-address');
var option = require('form-widget-option');
var embed = require('form-widget-embed');
var layout = require('form-widget-layout');
var validate = require('form-widget-validate');

var reveal = function() {

	dom.fbForm.removeClass('fb-intro-running');
	dom.fbForm.addClass('fb-intro-complete');

};

var getSubmissionVal = function(val) {

	return val ? val : '(no entry)';

};

module.exports = {

	init: function(opts) {

		console.log( 'initing fbWidget...' );

		var defaults = {
			elId: 'fbForm',
			forceOneColMode: false
		};

		opts = $.extend({}, defaults, opts);

		dom.init(opts.elId);
		settings.init();
		datepicker.init();
		selectmenu.init();
		time.init();
		rating.init();
		fileupload.init();
		familyfriends.init();
		address.init();
		option.init();
		embed.init();
		layout.init(opts.forceOneColMode);
		reveal();
		validate.init();
		placeholderToggle.init();

		emitter.emit('fbWidget-inited');

	},

	destroy: function() {

		emitter.emit('fbWidget-destroy');

		emitter.removeAllListeners();

	},

	reset: function() {

		dom.fbForm.trigger('reset');
		this.enable();

	},

	disable: function() {

		validate.disable();

	},

	enable: function() {

		validate.enable();

	},

	showSubmission: function(timestamp) {

		var markup = '';

		$.each(validate.submissionData.fields, function(i, fieldObj) {

			if ($.isArray(fieldObj.value)) {

				if (fieldObj.name !== 'Full Name') {

					markup += '<div class="fb-submission-field-header">' + fieldObj.name + '</div>';

				}

				$.each(fieldObj.value, function(i, nestedFieldObj) {

					markup += '<div class="fb-submission-field-label">' + nestedFieldObj.name + ':</div>';
					markup += '<div class="fb-submission-field-value">' + getSubmissionVal(nestedFieldObj.value) + '</div>';

				});

			} else {

				markup += '<div class="fb-submission-field-label">' + fieldObj.name + ':</div>';
				markup += '<div class="fb-submission-field-value">' + getSubmissionVal(fieldObj.value) + '</div>';

			}

		});

		dom.submissionFields.prepend(markup);

		dom.submissionTime.html(timestamp);

		dom.fbForm.addClass('fb-show-submission');

		scrollElement();

	},

	get submissionHeaderText() {

		return dom.submissionHeader.text();

	},

	get submissionMsgText() {

		return dom.submissionMsg.text();

	},

	get title() {

		return dom.title.text();

	},

	get form() {

		return dom.fbForm;

	}

};
