'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var scrollToElement = require('scroll-to-element');

var $body;
var defaults = {
	position: 'top',
	buffer: 15,
	classPrefix: 'notify',
	customClasses: '',
	type: 'error',
	animationDistance: 10,
	showClose: true
};
var state = {
	$notification: '',
	opts: {}
};

module.exports = {

	position: function(animate) {

		var targetCoords;
		var targetSize;
		var targetCenter;
		var notifyCoords = {};
		var notifySize = {
			w: 0,
			h: 0
		};
		var animationCss = {
			opacity: 1
		};
		var distance = animate ? state.opts.animationDistance : 0;

		if (this.isNotificationActive()) {

			targetCoords = this.target.offset();

			targetSize = {
				w: this.target.outerWidth(),
				h: this.target.outerHeight()
			};

			targetCenter = {
				x: targetCoords.left + targetSize.w / 2,
				y: targetCoords.top + targetSize.h / 2
			};

			notifySize.w = state.$notification.outerWidth();
			notifySize.h = state.$notification.outerHeight();

			if (state.opts.position === 'top') {

				notifyCoords.top =
					targetCoords.top -
					notifySize.h -
					state.opts.buffer -
					distance;

				notifyCoords.left = targetCenter.x - notifySize.w / 2;

				animationCss.top = '+=' + distance;

			} else if (state.opts.position === 'bottom') {

				notifyCoords.top =
					targetCoords.top +
					notifySize.h +
					state.opts.buffer +
					distance;

				notifyCoords.left = targetCenter.x - notifySize.w / 2;

				animationCss.top = '-=' + distance;

			} else if (state.opts.position === 'right') {

				notifyCoords.top = targetCenter.y - notifySize.h / 2;

				notifyCoords.left =
					targetCoords.left +
					notifySize.w +
					state.opts.buffer +
					distance;

				animationCss.left = '-=' + distance;

			} else if (state.opts.position === 'left') {

				notifyCoords.top = targetCenter.y - notifySize.h / 2;

				notifyCoords.left =
					targetCoords.left -
					notifySize.w -
					state.opts.buffer -
					distance;

				animationCss.left = '+=' + distance;

			}

			if (notifyCoords.left < 11) {

				notifyCoords.left = 11;

			}

			state.$notification
				.css({
					top: notifyCoords.top,
					left: notifyCoords.left
				})
				.addClass(state.opts.classPrefix + '-positioned');

			// this.scrollIntoView();

			if (animate) {

				// state.$notification.css(animationCss);
				state.$notification.animate(animationCss);

			}

		}

	},

	show: function(options) {

		this.hide();

		state.opts = $.extend({}, defaults, options || {});
		state.$notification = $('<div class="' + state.opts.classPrefix + '-container"><div class="' + state.opts.classPrefix + '-close-container"><span class="' + state.opts.classPrefix + '-close">x</span></div><span class="' + state.opts.classPrefix + '-msg">' + this.msg + '</span></div>');

		$body = $body || $('body');

		$body.append(state.$notification.addClass(state.opts.classPrefix + '-' + state.opts.position + ' ' + state.opts.classPrefix + '-' + state.opts.type + ' ' + (state.opts.showClose ? state.opts.classPrefix + '-show-close' : '') + state.opts.customClasses));

		this.position(true);

		this.scrollIntoView();

		state.$notification.find('.notify-close-container').on('click', this.hide.bind(this));

		return state.$notification;

	},

	hide: function() {

		if (this.isNotificationActive()) {

			state.$notification.remove();

		}

		state.$notification = '';
		state.opts = {};

	},

	scrollIntoView: function(opts) {

		/*

		scrollTopOffset: number of pixels to offset scrollTop position, relative to elementSelector - default 15
		scrollLeftOffset: number of pixels to offset scrollLeft position, relative to elementSelector - default 15
		speed: number of ms for scroll animation - default 0

		*/

		opts = opts || {};
		opts.speed = opts.speed || 0;
		opts.scrollTopOffset = typeof opts.scrollTopOffset === 'undefined' ? 15 : opts.scrollTopOffset;
		opts.scrollLeftOffset = typeof opts.scrollLeftOffset === 'undefined' ? 15 : opts.scrollLeftOffset;

		return scrollToElement({
			element: state.$notification,
			speed: opts.speed,
			scrollTopOffset: opts.scrollTopOffset,
			scrollLeftOffset: opts.scrollLeftOffset
		});

	},

	isNotificationActive: function() {

		return !!state.$notification.length;

	},

	isNotificationTarget: function($element) {

		return this.isNotificationActive() && $element.is(this.target);

	},

	get msg() {

		return state.opts.msg;

	},

	get target() {

		return state.opts.target;

	}

};