'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var imageGrid = require('image-grid');
var fullscreenSlideshow = require('fullscreen-slideshow');

var appDom = require('portfolio-dom');
var modal = require('portfolio-modal');
var scroller = require('portfolio-scroller');
var music = require('portfolio-music');

var dom;

var isGalleryGrid = function() {

	return window.core.isGalleryPage();

};

var destroy = function() {

	dom = null;
	appDom.window.off('.image-grid');
	appDom.document.off('.image-grid');
	imageGrid.destroy();

	if (isGalleryGrid()) {

		fullscreenSlideshow.destroy();

	}

};

var setupDom = function() {

	dom = {};

	dom.imageGrid = appDom.content.find('.image-grid');

};

var addEventHandlers = function() {

	if (isGalleryGrid()) {

		// Show spinner when loading fullscreen slideshow images.
		appDom.document.on('fullscreen_slideshow_image_load_start.image-grid', window.core.spinnerShow);

		// Hide spinner when finished loading fullscreen slideshow images.
		appDom.document.on('fullscreen_slideshow_image_load_finish.image-grid', window.core.spinnerHide);

		// Toggle music on/off when the button is clicked.
		appDom.document.on('fullscreen_slideshow_music_toggle.image-grid', music.toggle);

		// Handle caption clicks.
		appDom.document.on('fullscreen_slideshow_caption_click.image-grid', function(e) {

			var data = e.imgData;
			var url = data.caption.url;

			if (data.caption.isExternal) {

				if (url.indexOf('http') === -1) {

					url = 'http://' + url;

				}

				window.location.href = url;

			} else {

				// Simulate menu click to make sure menu state gets updated.
				$("a[data-page=" + data.caption.internalLinkId + "]").click();
				window.location.hash = url;

			}

		});

		appDom.document.on('fullscreen_slideshow_enter.image-grid', function() {

			// Reset spinner to be positioned relative to window.
			window.core.positionSpinner('window');

		});

		appDom.document.on('fullscreen_slideshow_exit.image-grid', function() {

			// Hide and reset spinner to be positioned relative to content.
			window.core.spinnerHide();
			window.core.positionSpinner();

		});

	}

	dom.imageGrid.on('click', '.image-grid__item', function() {

		var $item = $(this);
		var itemIndex;
		var linkData;
		var linkTarget;

		if (isGalleryGrid()) {

			// Launch fullscreen slideshow.

			itemIndex = $item.attr('data-image-grid-index');

			fullscreenSlideshow.launch(itemIndex, music.isPaused());

		} else {

			// List page grid.

			linkData = $item.find('.image-grid__image').data('list-link');
			linkTarget = linkData.target;

			if (linkTarget === 'lightbox') {

				// Open in our lightbox.
				modal.open(
					linkData.url,
					linkData.lightbox.width,
					linkData.lightbox.height,
					linkData.lightbox.caption
				);

			} else if (linkTarget === 'embedded') {

				// Open in our lightbox, but load it through our embed page.
				modal.open(
					'?load=embed&itemID=' + linkData.lightbox.itemId,
					linkData.lightbox.width,
					linkData.lightbox.height,
					linkData.lightbox.caption
				);

			} else {

				// Possible external link.

				if (linkTarget) {

					// Open in new window,
					window.open(linkData.url, linkTarget);

				} else if (linkData.url) {

					// Open in same window.
					window.location.href = linkData.url;

				}

			}

		}

	});

};

var init = function() {

	scroller.init();
	setupDom();
	addEventHandlers();

	// fullscreen-slideshow must be inited before the image-grid because if
	// the images are moved around for a masonry layout, the item indexes
	// won't match the order of dom nodes when fullscreen-slideshow is inited.
	if (isGalleryGrid()) {

		fullscreenSlideshow.init();

	}

	imageGrid.init();
	window.core.addUnload(destroy);

};

module.exports = {

	init: init

};
