'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var _ = require('underscore');

var imageProportion = require('image-proportion');
var lazyImage = require('lazy-image');
var focalPoint = require('focal-point');

var dom;
var state;
var options;

var getDefaults = function() {

	return {
		isMasonry: false,
		isTextHidden: false,
		numCols: 0,
		gutterSize: 0,
		// Proxy lazy-image options.
		customScroller: '',
		customScrollerEvent: ''
	};

};

var getColWidth = function() {

	return (dom.grid.width() - options.gutterSize * (options.numCols - 1)) / options.numCols;

};

var getGutterSize = function() {

	return parseInt(dom.grid.css('margin-left'), 10);

};

var applyMasonry = function() {

	// Store a data object for each column.
	var colData = [];

	dom.cells.each(function(i) {

		var $cell = dom.cells.eq(i);
		var $item = $cell.find('.image-grid__item');
		var $img = $item.find('.image-grid__image');
		var itemData = $img.data('image-grid');
		var targetCol;

		// Determine the image height by proportionally scaling it based
		// on the column width. The image will fill the column width.
		var scaledHeight = imageProportion.scaleByWidth(itemData.width, itemData.height, state.colWidth);

		// $img.css('padding-bottom', 'calc(100% * ' + itemData.height + ' / ' + itemData.width + ')');
		$img.css('padding-bottom', (imageProportion.scaleByWidth(itemData.width, itemData.height, 100)) + '%');

		if (i < options.numCols) {

			// Leave the first "row" of images where they are.

			targetCol = i;

			// Create a data object for each column. Store the index and
			// a running total of the column height. We'll use the index
			// as an easy pointer afer the columns are sorted.
			colData[targetCol] = {
				col: targetCol,
				height: 0
			};

		} else {

			// Sort colData to find the column with the smallest
			// height. The current item should be moved to this column.

			targetCol = _.sortBy(colData, 'height')[0].col;

			// Move the item into the shortest column.
			dom.cells.eq(targetCol).append($item);

			// Remove empty cell from the dom.
			$cell.remove();

		}

		// Increment the height for this column.

		if (!options.isTextHidden) {

			// Item height equals image height and text height.
			scaledHeight += $item.height();

		}

		colData[targetCol].height += scaledHeight;

	});

};

var setupDom = function() {

	dom = {};

	dom.grid = $('.image-grid');
	dom.cells = dom.grid.find('.image-grid__cell');

};

var setupInitialState = function() {

	state = {};

	state.colWidth = getColWidth();

};

var init = function() {

	setupDom();
	options = getDefaults();
	options.gutterSize = getGutterSize();
	$.extend(options, dom.grid.data('image-grid-options'));

	if (options.isMasonry) {

		setupInitialState();
		applyMasonry();

	} else {

		// We only need to apply focal points to non-masonry layouts b/c
		// masonry layouts show the full image.
		focalPoint.init({
			target: '.image-grid [data-focal-point]'
		});

	}

	lazyImage.init({
		target: '.image-grid [data-lazy-image]',
		customScroller: options.customScroller,
		customScrollerEvent: options.customScrollerEvent
	});

};

var destroy = function() {

	dom = null;
	options = null;
	state = null;
	lazyImage.destroy();
	focalPoint.destroy();

};

module.exports = {

	init: init,
	destroy: destroy

};
